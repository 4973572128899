import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

const Message = ({ msg, bgColor, timing }) => {
  const [removeAlert, setRemoveAlert] = useState(false)

  useEffect(() => {
    let joy = true;
    setTimeout(function(){
      setRemoveAlert(true);
    }, timing ? timing : 12000)
    return () => {
      joy = false;
      setRemoveAlert(false);
    }
  }, [])
 
  return (
    !removeAlert && (<div className={`alert ${bgColor} alert-dismissible fade show`} role='alert'>
      {msg}
    {/*   <button
        type='button'
        className='close'
        data-dismiss='alert'
        aria-label='Close'
      > */}
      <button
        type='button'
        className='close'
        onClick={()=>setRemoveAlert(true)}
      >
       {/*  <span aria-hidden='true'>&times;</span> */}
        <span>&times;</span>
      </button>
    </div>)
  );
};

Message.propTypes = {
  msg: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired
};

export default Message;
