// Chakra imports
import { SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import Information from "views/admin/profile/components/Information";
import { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios'
import {
  Link,
ModeValue,
} from "@chakra-ui/react";

// Assets
export default function GeneralInformation(props) {
  const { data, fLCapital, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const [loaded, setLoaded] = useState(false);
  const [items, setItems] = useState([]);

  /* function sendToCategoryPage(catty) {
    sessionStorage.setItem('testigory', catty);
    let daty = new Date();
var res = daty.toISOString().slice(0,10).replace(/-/g,"");
    setTimeout(() => {
     /*  <Redirect push to={`/#/admin/category?cs="+${res}+"#homek`} /> ****
     window.location.href = `/#/admin/category?cat=${catty}&cs=${res}#homek`
    }, 3000)
  } */

  console.log("GENERAL ITEMS", props)
  return (
    <Card mb={{ base: "0px", "2xl": "20px" }} {...rest}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='4px'>
        Latest Testimony
      </Text>
      <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
      { fLCapital(data).substring(0, 80)+"..."} <Link href="/admin/single?dia=1" style={{color: 'var(--chakra-colors-brand-500)'}}>Read Full</Link>
      </Text>
      <SimpleGrid columns='2' gap='20px'>
      <Link href={"/#/admin/defaulit?ct=Health"}>
          <Information
            boxShadow={cardShadow}
            title='Health'
            value='Testifiers:  209'
           /*  onClick={() => sendToCategoryPage('Health')} */
          />
        </Link>

      <Link href={"/#/admin/defaulit?ct=Finance"}>
          <Information
            boxShadow={cardShadow}
            title='Finance'
            value='Testifiers: 67'
           /*  onClick={() => sendToCategoryPage('Finance')} */
          />
      </Link>

      <Link href={"/#/admin/defaulit?ct=Career"}>
          <Information
            boxShadow={cardShadow}
            title='Career'
            value='Testifiers: 56' 
           /*  onClick={() => sendToCategoryPage('Career')} */
          />
        </Link>

        <Link href={"/#/admin/defaulit?ct=Relationship"}>
          <Information
            boxShadow={cardShadow}
            title='Relationship'
            value='Testifiers: 89'
         /*    onClick={() => sendToCategoryPage('Relationship')} */
          />
        </Link>

        <Link href={"/#/admin/defaulit?ct=Protection"}>
          <Information
            boxShadow={cardShadow}
            title='Protection'
            value='Testifiers: 78'
          /*   onClick={() => sendToCategoryPage('Protection')} */
          />
        </Link>
        <Link href={"/#/admin/defaulit?ct=Fruit of the womb"}>
          <Information
            boxShadow={cardShadow}
            title='Fruit of the womb'
            value='Testifiers:  459'
          /*   onClick={() => sendToCategoryPage('Fruit of the womb')} */
          />
        </Link>

      </SimpleGrid>
    </Card>
  );
}
