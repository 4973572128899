import React from 'react'
import {
    Avatar,
    Box,
    Flex,
    FormLabel,
    Icon,
    Select,
    SimpleGrid,
    useColorModeValue,
    Heading
  } from "@chakra-ui/react";
  // Assets
  import Usa from "assets/img/dashboards/usa.png";
  import img1 from "assets/img/auth/img1.png";
  import img2 from "assets/img/auth/img2.jpg";
  // Custom components
  import MiniCalendar from "components/calendar/MiniCalendar";
  import MiniStatistics from "components/card/MiniStatistics";
  import IconBox from "components/icons/IconBox";

  import {
    MdAddTask,
    MdAttachMoney,
    MdBarChart,
    MdFileCopy,
    MdPendingActions,
    MdPending,
    MdPieChart
  } from "react-icons/md";

  import { FaRegRegistered, FaBuilding, FaGripVertical, FaBullhorn, FaThumbsUp } from "react-icons/fa";
  import axios from "axios";
  import {useState, useEffect} from "react";

const WidgetsAfterLogin = () => {
    // Chakra Color Mode
    const brandColor = useColorModeValue("brand.500", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

    const [loaded, setLoaded] = useState(false);
    const [members, setMembers] = useState([]);
    const [memberCounter, setMemberCounter] = useState(0);
    const [churchCounter, setChurchCounter] = useState(0);
    const [testimonyCounter, setTestimonyCounter] = useState(0);

  
    const host = 'http://localhost:3050';
    useEffect(() => {
          async function fetcherMember() {
            await axios.get(`${host}/api/testify/allmembers`)
            .then(function (result) {
             // setLoaded(true);
              setMemberCounter(result.data.count);
                console.log("Members counter", memberCounter);
            })
          }
          fetcherMember();
  }, [memberCounter]);

  useEffect(() => {
    async function fetcherChurch() {
      await axios.get(`${host}/api/testify/allchurches`)
      .then(function (result) {
        //setLoaded(true);
        setChurchCounter(result.data.count);
          console.log("Churches counter", churchCounter);
      })
    }
    fetcherChurch();
}, [churchCounter]);

  useEffect(() => {
    async function fetcherTestimony() {
      await axios.get(`${host}/api/testify/all`)
      .then(function (result) {
        //setLoaded(true);
        setTestimonyCounter(result.data.count);
          console.log("Testimony counter", testimonyCounter);
      })
    }
    fetcherTestimony();
}, [testimonyCounter]);

  return (
    <>
        <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'>
       <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={FaRegRegistered} color={brandColor} />
              }
            />
          }
          name='Registered Members'
          value={memberCounter}
        />
      
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={FaBuilding} color={brandColor} />
              }
            />
          }
          name='Registered Churches'
          value={churchCounter}
        />
 
        <MiniStatistics 
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={FaBullhorn} color={brandColor} />
              }
            />
          }
        /*   growth='M'   */
          name='Total Testimonies' 
          value={testimonyCounter}
        />

       {/*  <MiniStatistics
         startContent={
          <IconBox
            w='56px'
            h='56px'
            bg={boxBg}
            icon={
              <Icon w='32px' h='32px' as={FaThumbsUp} color={brandColor} />
            }
          />
        }
        
          name='Total Likes'
          value='100'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={FaGripVertical} color={brandColor} />
              }
            />
          }
          name='My Testimony'
          value='2'
        />

        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdPieChart} color={brandColor} />
              }
            />
          }
          name='Charts'
          value='12'
        /> */}
      </SimpleGrid>
    </>
  )
}

export default WidgetsAfterLogin