/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useEffect, useState } from 'react'
import axios from 'axios'
// Chakra imports
import { Box, Grid } from "@chakra-ui/react";

// Custom components
import Banner from "views/admin/profile/components/Banner";
import General from "views/admin/profile/components/General";
import Notifications from "views/admin/profile/components/Notifications";
import Projects from "views/admin/profile/components/Projects";
import Storage from "views/admin/profile/components/Storage";
import Upload from "views/admin/profile/components/Upload";
import ModalVideo from 'react-modal-video'

// Assets
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import likes from "assets/img/likes.png";
import React from "react";

export default function Overview() {
  const [isOpen, setIsOpen] = React.useState(false); 
  
  const [loaded, setLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [data, setData] = useState('');
  

  function openModal() {
    setIsOpen(true);
  }

   /* 
   MEMBER
   `detels {"success":true,"message":"Login successful","member":{"isVerified":false,"memberid":3,"member_name":"Omowaye saregbagi","churchid":45,"address":"No 1 Eebudola Street, Alagbole, Ogun State, Nigeria","website":"omowayes.com","fbpage":"fb.com/omowayes","email":"omowayes@gmail.com","mobileno":"08068918827","gender":"male","pastor_name":"Pa omowayes","password":"$2b$10$V7R3TMZcliGz9c67TPyO1.HzCwGDwFgKnVD4y4BX0rZ8wbg0bNq0e","usertype":"member","photo":null,"city":"Lagos","country":"Nigeria","verificationToken":null,"verified":null,"resetToken":null,"resetTokenExpires":null,"passwordReset":null,"upgradeDate":null,"createdAt":"2022-08-17T17:42:20.000Z","updatedAt":"2022-08-17T17:42:20.000Z"}` 
   
   CHURCH
   memdetels {"success":true,"message":"Login successful","churchAdmin":{"isVerified":false,"churchid":20,"churchname":"Grace Assembly","address":"km 12, express","website":"me.com","fbpage":"fb.com/kk","email":"mossy1kme@gmail.com","mobileno":"08068918827","gender":"male","pastor_name":"Pa k","password":"$2b$10$0PXFbt4N04Ye/01nCyrkmuVpJ70YZCPYeuP9K2FLBTGY8t8iF3XUS","city":"Lagos","country":"Nigeria","verificationToken":null,"verified":null,"resetToken":null,"resetTokenExpires":null,"passwordReset":null,"upgradeDate":null,"createdAt":"2022-08-17T03:35:28.000Z","updatedAt":"2022-08-17T03:35:28.000Z"}
   */

  let memdetels = sessionStorage.getItem('memberdetails') !== null ? sessionStorage.getItem('memberdetails') : sessionStorage.getItem('churchdetails')
  console.log("memdetels", memdetels)
  let midArr = JSON.parse(""+memdetels+"");

  useEffect(() => {
    let first =false;
    const host = "http://localhost:3050";
          async function fetcher() {
            await axios.get(`${host}/api/testify/all`)
            .then(function (result) {
              setLoaded(true);
              setItems(result.data.data);
              setData(Object.values(result.data.data)[0].testimonyDesc);
                console.log("RESOORT", result.data.data[0].id);
            })
          }
          if(!first) {
            fetcher();
          }
          (() => {
            first = false
          })()
        }, []);
        console.log("API RESULT", items)
        let fLCapital = s => s = s.charAt(0).toUpperCase() + s.slice(1);

  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "1.34fr 1fr 1.62fr",
        }}
        templateRows={{
          base: "repeat(3, 1fr)",
          lg: "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>
        <Banner
          gridArea='1 / 1 / 2 / 2'
          banner={banner}
          avatar={likes}
          openModal={openModal}
          name={midArr.churchAdmin ? midArr.churchAdmin.churchname : midArr.member.member_name}
          job={midArr.churchAdmin ? midArr.churchAdmin.fbpage : midArr.member.fbpage}
          posts='18'
          followers='9.7k'
          following='1'
          totalposts='1800'
          totalfollowers='19.7k'
          totalfollowing='3'
        />
        <Storage
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          used={25.6}
          total={50}
        />
        <Upload
          gridArea={{
            base: "3 / 1 / 4 / 2",
            lg: "1 / 3 / 2 / 4",
          }}
          minH={{ base: "auto", lg: "420px", "2xl": "365px" }}
          pe='20px'
          pb={{ base: "100px", lg: "20px" }}
        />
      </Grid>
      <Grid
        mb='20px'
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1.34fr 1.62fr 1fr",
        }}
        templateRows={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>
        <Projects
          gridArea='1 / 2 / 2 / 2'
          banner={banner}
          avatar={avatar}
          name='Adeola Babajide'
          job='Product Designer'
          posts='17'
          followers='9.7k'
          following='274'
          items={items}
          fLCapital={fLCapital}
        />
        <General
          gridArea={{ base: "2 / 1 / 3 / 2",
          lg: "1 / 2 / 2 / 3" }}
          minH='365px'
          pe='20px'
          data={data}
          fLCapital={fLCapital}
        />
        <Notifications
          used={25.6}
          total={50}
          gridArea={{
            base: "3 / 1 / 4 / 2",
            lg: "2 / 1 / 3 / 3",
            "2xl": "1 / 3 / 2 / 4",
          }}
        />

<ModalVideo channel='youtube' isOpen={isOpen} videoId='L61p2uyiMSo' onClose={() => setIsOpen(false)} />

      </Grid>
    </Box>
    </>
  );
}
