import { useState, useEffect } from 'react'
// Chakra imports
import { Text, useColorModeValue } from "@chakra-ui/react";
// Assets
import Project1 from "assets/img/profile/Project1.png";
import Project2 from "assets/img/profile/Project2.png";
import Project3 from "assets/img/profile/Project3.png";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import Project from "views/admin/profile/components/Project";

export default function Projects(props) {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  return (
    <Card mb={{ base: "0px", "2xl": "20px" }}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='4px'>
        Recent Testimonies
      </Text>
      <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
        Here you can find the latest testimonies in your church. Kindly click on 
        the details to see it all...
      </Text>

      {props.items.map((item, index) => (
      index > 0 && index <= 3 && 
      <Project
        boxShadow={cardShadow}
        mb='20px'
        image={item.photo}
        ranking='1'
        link='#'
        title={item.title}
        desc={item.testimonyDesc}
        id={item.id}
        timer={item.createdAt.split('T')[0]}
        fLCapital={props.fLCapital}
        testifiername={item.testifiername}
      />
     /*  <Project
        boxShadow={cardShadow}
        mb='20px'
        image={Project2}
        ranking='2'
        link='#'
        title='I want to appreciate the Almighty God for healing my daughter of severe...'
      />
      <Project
        boxShadow={cardShadow}
        image={Project3}
        ranking='3'
        link='#'
        title='There were many challenges during my engagement...'
      /> */
      ))}
    </Card>
  );
}
