/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import { NavLink } from "react-router-dom";
import axios from 'axios'
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Select,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye, MdArrowDropDown } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

function SignUp() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const [churchName, setChurchName] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [gender, setGender] = React.useState('');
  const [website, setWebsite] = React.useState('');
  const [fbpage, setFbPage] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [mobileNo, setMobileNo] = React.useState('');
  const [pastorName, setPastorName] = React.useState('');
  const [adminName, setAdminName] = React.useState('');
  const [url, setUrl] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [city, setCity] = React.useState('');
  const [country, setCountry] = React.useState('');
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [registerText, setRegisterText] = React.useState('Register Now');

  async function submitForm() {
    const myForm = {
      churchname: churchName,
      address,
      gender,
      website,
      fbpage,
      email,
      mobileno: mobileNo,
      pastor_name: pastorName,
      password,
      city,
      country
    }

    if(churchName === '' || address === ''  || gender === '' || website === '' || email === '' || mobileNo === '' || password === '' || city === '' || country === '') {
      setError(!error);
      return;
    }
  const host = 'http://localhost:3050';
  setRegisterText('Registering, please wait...')

  setTimeout(() => {
    setRegisterText('Register Now')
  }, 5000)

  console.log("Form Data", myForm);

  await axios.post(`${host}/api/testify/register`, myForm)
  .then(function (result) {
    console.log("RESPONSE", result);
    if(result.data.success === true) {
     
      sessionStorage.setItem('churchdetails', JSON.stringify(result.data));

      setSuccess(!success)

     
      setTimeout(() => {
        setSuccess(false)
      history.push("/admin/profile"); 
    }, 5000)

    } 
  })
.catch(function (err) {
  //throw err
    if(err) {
      setError(!error) 
      setRegisterText('Register');
    }
    setTimeout(() => {
      setError(false)
      setRegisterText('Register')
    }, 6000)
    //console.log(err.response.data.status);
  });
}


  if(window.innerWidth > 650) {
    setTimeout(() => {
      document.documentElement.style.backgroundColor = "white";
    }, 200);
  }


 // console.log("CHURH NAME: ", churchName);

  const handleClick = () => setShow(!show);
  return (
    <React.Fragment>
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "120px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Sign Up
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Create A Church Account!
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <Button
            fontSize='sm'
            me='0px'
            mb='26px'
            py='15px'
            h='50px'
            borderRadius='16px'
            bg={googleBg}
            color={googleText}
            fontWeight='500'
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}>
            <Icon as={FcGoogle} w='20px' h='20px' me='10px' />
            Sign in with Google
          </Button>
          <Flex align='center' mb='30px'>
            <HSeparator />
          
            <HSeparator />
          </Flex>
          <FormControl>
          <Box me='auto'><br/><br/><br/><br/><br/><br/>
            <Heading color={textColor} fontSize='36px' mb='10px' mt='20px'>
              Church Registration
            </Heading>
          </Box>

            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Church Name<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='Enter Church Name'
              mb='24px'
              fontWeight='500'
              size='lg'
              value={churchName}
              onChange={(e)=>setChurchName(e.target.value)}
              required
            />

            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Address<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='Enter Church Address'
              mb='24px'
              fontWeight='500'
              size='lg'              
              value={address}
              onChange={(e)=>setAddress(e.target.value)}
              required
            />

            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Gender<Text color={brandStars}>*</Text>
            </FormLabel>
            <Select icon={<MdArrowDropDown />} placeholder='Select Your Gender' mb='24px'  onChange={(e) => setGender(e.target.value)}>
              <option value='male'>Male</option>
              <option value='female'>Female</option>
            </Select>

            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Website<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='text'
              placeholder='Enter your Church Url'
              mb='24px'
              fontWeight='500'
              size='lg'              
              value={website}
              onChange={(e)=>setWebsite(e.target.value)}
            />

            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Facebook Page<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='Enter Facebook Page Url'
              mb='24px'
              fontWeight='500'
              size='lg'              
              value={fbpage}
              onChange={(e)=>setFbPage(e.target.value)}
            />

            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='mail@simmmple.com'
              mb='24px'
              fontWeight='500'
              size='lg'              
              value={email}
              onChange={(e)=>setEmail(e.target.value)}
            />

            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Mobile Number<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='text'
              placeholder='Enter Phone Number'
              mb='24px'
              fontWeight='500'
              size='lg'              
              value={mobileNo}
              onChange={(e)=>setMobileNo(e.target.value)}
            />

            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Pastor Name<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='text'
              placeholder='Enter Pastor Name'
              mb='24px'
              fontWeight='500'
              size='lg'              
              value={pastorName}
              onChange={(e)=>setPastorName(e.target.value)}
            />
{/* 
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              URL <Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='Enter url'
              mb='24px'
              fontWeight='500'
              size='lg'              
              value={url}
              onChange={(e)=>setUrl(e.target.value)}
            /> */}
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              City <Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='text'
              placeholder='Enter Your City Name'
              mb='24px'
              fontWeight='500'
              size='lg'              
              value={city}
              onChange={(e)=>setCity(e.target.value)}
            />
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Country <Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='text'
              placeholder='Enter Your Country Name'
              mb='24px'
              fontWeight='500'
              size='lg'              
              value={country}
              onChange={(e)=>setCountry(e.target.value)}
            />

            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'>
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
              <Input
                isRequired={true}
                fontSize='sm'
                placeholder='Min. 8 characters'
                mb='24px'
                size='lg'
                type={show ? "text" : "password"}
                variant='auth'
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Flex justifyContent='space-between' align='center' mb='24px'>
              <FormControl display='flex' alignItems='center'>
                <Checkbox
                  id='remember-login'
                  colorScheme='brandScheme'
                  me='10px'
                />
                <FormLabel
                  htmlFor='remember-login'
                  mb='0'
                  fontWeight='normal'
                  color={textColor}
                  fontSize='sm'>
                  Keep me logged in
                </FormLabel>
              </FormControl>
              <NavLink to='/auth/forgot-password'>
                <Text
                  color={textColorBrand}
                  fontSize='sm'
                  w='124px'
                  fontWeight='500'>
                  Login instead?
                </Text>
              </NavLink>
            </Flex>
            <Button
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='44px'
              mt="-16px"
              onClick={submitForm}
              >
              {registerText}
            </Button>
          </FormControl>
        {error && (<Box display="flex" position="fixed" top="20%" left="20%"> 
          <Alert status='error'>
            <AlertIcon />
            Your Email is already used. Please kindly choose another one
          </Alert>
        </Box>)
        }  
        
       { success && (<Box display="flex" position="fixed" top="20%" left="20%">  
          <Alert status='success' variant='subtle'>
            <AlertIcon />
              Congratulations! Your registration was successful!
          </Alert>
        </Box>)
        }

          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='40px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              Not registered yet?
           
              <NavLink to='/auth/sign-up-member'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  Create a Member Account
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
    </React.Fragment>
  );
}

export default SignUp;
