import React from "react";

// Chakra imports
import { Button, Flex, Text, Box } from "@chakra-ui/react";

// Assets
import banner1 from "assets/img/nfts/NftBanner1.png";
// Assets
import banner from "assets/img/nfts/testimonial.png";
import ModalVideo from 'react-modal-video'
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import { Link } from 'react-router-dom'


export default function Banner() {
const [isOpen, setIsOpen] = React.useState(false)

 function openModal () {
    setIsOpen(!isOpen)
  }

  function removeIframeVideo() {
    setTimeout(() => {
      setIsOpen(false)
    }, 3000)
  }
  // Chakra Color Mode
  return (
    <>
   {isOpen &&  
   <>
   <div style={{position: 'fixed',top:'30%', left: '30%', border: '0px solid #fff', width: 'auto', height: 'auto', background: 'transparent', opacity: '0.95', zIndex: '99999999999999999999999999999999999999999'}}>
   <span onClick={removeIframeVideo} style={{position: 'absolute', right: '-4%', top: '-10%', fontSize: '22px', color: '#f5f8fa', fontWeight: '600', cursor: 'pointer'}}>X</span>
   {/*  <Box  background= 'transparent' opacity= ''> */}
   <iframe title="myframer" width='460' height = '230' src={'//www.youtube.com/embed/L61p2uyiMSo?autoplay=1&cc_load_policy=1&controls=1&disablekb=0&enablejsapi=0&fs=1&iv_load_policy=1&loop=0&rel=0&showinfo=1&start=0&wmode=transparent&theme=dark&mute=0'} frameBorder='0' allow={'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'} allowFullScreen={true} tabIndex='-1' />
    {/* <ModalVideo channel='youtube' isOpen={isOpen} videoId='L61p2uyiMSo' onClose={() => setIsOpen(true)} /> */}
  {/*   </Box> */}
    </div></>}
    <Flex
      direction='column'
      bgImage={banner}
      bgSize='cover'
      py={{ base: "30px", md: "56px" }}
      px={{ base: "30px", md: "64px" }}
      borderRadius='30px'>
      <Text
        fontSize={{ base: "24px", md: "34px" }}
        color='white'
        mb='14px'
        maxW={{
          base: "100%",
          md: "64%",
          lg: "46%",
          xl: "70%",
          "2xl": "60%",
          "3xl": "42%",
        }}
        fontWeight='700'
        lineHeight={{ base: "32px", md: "42px" }}>
        Become an overcomer by Testimony...
      </Text>
      <Text
        fontSize='md'
        color='#E3DAFF'
        maxW={{
          base: "100%",
          md: "64%",
          lg: "40%",
          xl: "56%",
          "2xl": "46%",
          "3xl": "34%",
        }}
        fontWeight='500'
        mb='40px'
        lineHeight='28px'>
        "And they overcame him by the blood of the Lamb, and by the word of their testimony; and they loved not their lives unto the death." &nbsp;&nbsp;Revelation 12:11
      </Text>
      <Flex align='center'>
        <Link to='/admin/default'><Button
          bg='white'
          color='black'
          _hover={{ bg: "whiteAlpha.900" }}
          _active={{ bg: "white" }}
          _focus={{ bg: "white" }}
          fontWeight='500'
          fontSize='14px'
          py='20px'
          px='27'
          me='38px'>
          Read Testimonies
        </Button>
        </Link>

        <Link>
          <Text color='white' fontSize='sm' fontWeight='500' onClick={openModal}>
            Watch video
          </Text>
        </Link>
      </Flex>
    </Flex>
    </>
  );
}
