import { useHistory, Redirect } from 'react-router-dom';
import SignInMember from 'views/auth/signIn/index-m';
const Logout = () => {
  const history = useHistory();
 // sessionStorage.clear();
  sessionStorage.removeItem('churchdetails');
  sessionStorage.removeItem('memberdetails');
  return <Redirect push to='http://localhost:3000/#/auth/sign-in-member'/>
  //window.location.href = 'http://localhost:3000/#/auth/sign-in-member'
  //history.push('/')

}

export default Logout