
// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import {Redirect} from "react-router-dom";

// Assets
import { MdEdit, MdAddLink } from "react-icons/md";

export default function Project(props) {
  const { id, title, desc, fLCapital, timer, testifiername, ranking, link, image, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const brandColor = useColorModeValue("brand.500", "white");
  const bg = useColorModeValue("white", "navy.700");

  const setNRedirect = (title) =>{
    sessionStorage.setItem('singleTitle', title);
    sessionStorage.setItem('singleDesc', desc);
    sessionStorage.setItem('singleId', id);
    sessionStorage.setItem('image', image);
    sessionStorage.setItem('timer', timer);
    sessionStorage.setItem('testifiername', testifiername);
    setTimeout(() => {
     // return <Redirect push to={`/#/admin/single`} />
     window.location.href = "/#/admin/single"
    }, 20);
  }

  return (
   
      <Card bg={bg} {...rest} p='14px'>
      <Flex align='center' direction={{ base: "column", md: "row" }}>
        <Image h='80px' w='80px' src={"/church_upload/"+image} borderRadius='8px' me='20px' />
        <Box mt={{ base: "10px", md: "0" }}>
          <Text
            color={textColorPrimary}
            fontWeight='500'
            fontSize='md'
            mb='4px'>
            <b style={{fontSize: '20px'}}>{title}</b><br/>
            {fLCapital(desc.substring(0, 60))+"..."}
          </Text>
          <Text
            fontWeight='500'
            color={textColorSecondary}
            fontSize='sm'
            me='4px'
            align='right'>
            {timer}{/* {ranking} •{" "} */}
            <Text fontWeight='500' color={brandColor} onClick = {() => setNRedirect(title)} fontSize='sm' style={{cursor: 'pointer'}}>
              See Testimony details...
            </Text>
          </Text>
        </Box>
        <Link
          href={link}
          variant='no-hover'
          me='16px'
          ms='auto'
          p='0px !important'>
          <Icon as={MdAddLink} color='secondaryGray.500' h='18px' w='18px' />
        </Link>
      </Flex>
    </Card>
    )
  
}
