/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import {useLocation} from 'react-router-dom'
// Chakra imports
import {
  SimpleGrid,
  useColorModeValue,
  Heading,
  Box
} from "@chakra-ui/react";
// Assets
import img1 from "assets/img/auth/img1.png";
import img2 from "assets/img/auth/img2.jpg";
// Custom components

import WidgetsAfterLogin from './WidgetAfterLogin'
import SearchPaginate2b from './searchPaginate2b' 


export default function UserReports2() {
  const pager = new URLSearchParams(window.location.search).get("pager")
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const search = useLocation().search;
  const cat = new URLSearchParams(search).get('ct')?new URLSearchParams(search).get('ct'):'health';

 /*  if(window.innerWidth > 650) {
    setTimeout(() => {
      document.documentElement.style.backgroundColor = "#e0e4eb";
    }, 200);
  } */

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>

     <WidgetsAfterLogin />

      <SimpleGrid mt="80px"  alignItems="center">
        <SearchPaginate2b cat={cat}/>
      </SimpleGrid>

      <SimpleGrid columns={2} spacingX='40px' spacingY='20px'  style={{display: 'flex'}}>
        <Box  height='auto'>
          <img src={img2} alt=""/>
        </Box>
        <Box  height='auto'>
        <img src={img1} alt=""/><br/><br/>
        </Box>
        </SimpleGrid>
     {/*  <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <TotalSpent />
        <WeeklyRevenue />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
        <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px'>
          <DailyTraffic />
          <PieCard />
        </SimpleGrid>
      </SimpleGrid>
      */}
    
      {/* {(window.location.href === "http://localhost:3000/admin#/admin/default") && <Heading as="h1"  mb={(window.location.href === "http://localhost:3000/admin#/admin/default") ? '-40px' : 'inherit'} mt={(window.location.href === "http://localhost:3000/admin#/admin/default") ? '60px' : 'inherit'}>List of Pending Testimonies</Heading>} */}
     
     {/*  {pager === 'pending' ? <GridBlurredBackdropPending /> : <GridBlurredBackdropApproved/>} */}

      
      {/* <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb={(window.location.href === "http://localhost:3000/admin#/admin/default") ? '20px' : 'inherit'}>
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        /> 
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          {/* <Tasks />
          <MiniCalendar h='100%' minW='100%' selectRange={false} />
        </SimpleGrid>
      </SimpleGrid> */}
    </Box>
  );
}
