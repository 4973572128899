import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdGroups,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdAppRegistration,
  MdPeopleAlt,
  MdPersonAdd,
  MdPersonAddAlt,
  MdPeopleOutline,
  MdRemoveRedEye
} from "react-icons/md";
import { PhoneIcon, AddIcon, PlusSquareIcon, WarningIcon, TimeIcon, CheckIcon } from '@chakra-ui/icons'
import Logout from "components/navbar/Logout";

// Admin Imports
import MainDashboard from "views/admin/default";
import MainDashboard2 from "views/admin/default/index2.jsx";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import TestimonyApprovedList from "views/admin/default/testimonyApprovedList";
import TestimonyPendingList from "views/admin/default/testimonyPendingList";
import AllCategories from "views/admin/dataTables/allcategories";
import FileUploader  from 'views/admin/default/fileUploadxxx'
import RTL from "views/admin/rtl";

// Auth Imports
import SignInCentered from "views/auth/signIn/index-c";
import SignInMember from "views/auth/signIn/index-m";
import SignUpChurchCentered from "views/auth/signup_church";
import SignUpMemberCentered from "views/auth/signup_member";
import TestimonySingle from 'views/admin/default/testimonySingle'
import { IoMdLogOut } from "react-icons/io";
import SearchPaginateCategory from "views/admin/default/searchPaginateCategory";
import SearchPaginateByChurch from "views/admin/default/searchPaginateByChurch";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  {
    name: "View By Category",
    layout: "/admin",
    path: "/defaulit",
    icon: <Icon as={MdRemoveRedEye} width='20px' height='20px' color='inherit' />,
    component: MainDashboard2,
  },
  {
    name: "Testimony Categories",
    layout: "/admin",
    path: "/categories",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: NFTMarketplace,
    secondary: true,
  },
  {
    name: "Recent Testimonies",
    layout: "/admin",
    urlStatus: "pending",
    icon: <Icon as={TimeIcon} width='20px' height='20px' color='inherit' />,
    path: "/latestestimonies",
    component: TestimonyPendingList,
  },
  {
    name: `${sessionStorage.getItem('testigory')} Testimony`,
    layout: "/admin",
    urlStatus: "pending",
    icon: <Icon as={TimeIcon} width='20px' height='20px' color='inherit' />,
    path: "/category",
    component: SearchPaginateCategory
  },
  {
    name: `${sessionStorage.getItem('churchname')} Testimony`,
    layout: "/admin",
    urlStatus: "pending",
    icon: <Icon as={TimeIcon} width='20px' height='20px' color='inherit' />,
    path: "/just-a-church",
    component: SearchPaginateByChurch
  },
  {
    name: "Single Testimony",
    layout: "/admin",
    urlStatus: "approved",
    icon: <Icon as={CheckIcon} width='20px' height='20px' color='inherit' />,
    path: "/single",
    component: TestimonySingle,
  },
  {
    name: "All Members Accounts",
    layout: "/admin",
    icon: <Icon as={MdPeopleOutline} width='20px' height='20px' color='inherit' />,
    path: "/data-tables",
    component: DataTables,
  },
  {
    name: "All Churches",
    layout: "/admin",
    icon: <Icon as={MdGroups} width='20px' height='20px' color='inherit' />,
    path: "/allchurches",
    component: AllCategories,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Profile,
  },
  {
    name: "Post A Testimony",
    layout: "/admin",
    path: "/testipost",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: FileUploader,
  },
  {
    name: "Log Out",
    layout: "/admin",
    path: "/logout",
    icon: <Icon as={IoMdLogOut} width='20px' height='20px' color='inherit' />,
    component: Logout,
  },
  {
    name: "Sign In Church",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
  },
  {
    name: "Sign In Member",
    layout: "/auth",
    path: "/sign-in-member",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInMember,
  },
  {
    name: "Church Sign Up",
    layout: "/auth",
    path: "/sign-up-church",
    icon: <Icon as={MdPersonAdd} width='20px' height='20px' color='inherit' />,
    component: SignUpChurchCentered,
  },
  {
    name: "Member Sign Up",
    layout: "/auth",
    path: "/sign-up-member",
    icon: <Icon as={MdPersonAddAlt} width='20px' height='20px' color='inherit' />,
    component: SignUpMemberCentered,
  },
  {
    name: "RTL Admin",
    layout: "/rtl",
    path: "/rtl-default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: RTL,
  },
];

export default routes;
