
import React, { Fragment, useState } from 'react';
import { useFormik, Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Message from './Message';
import Progress from './Progress';
import FileUpload3 from './FileUpload3.jsx';
import axios from 'axios';
import { post } from 'jquery';
import {  
          SimpleGrid, 
          Box,
          Flex, 
          FormControl, 
          FormLabel, 
          useColorModeValue, 
          Text, 
          Input, 
          Button, 
          Select,
          Textarea,
          Alert,
          AlertIcon 
        } from '@chakra-ui/react'

// Assets
import { MdArrowDropDown} from "react-icons/md";
 
 const PostATestimony = () => {

    const [file, setFile] = useState();
    const [filename, setFilename] = useState('Choose File');
    const [testifier, setTestifier] = useState('');
    const [churchunit, setChurchUnit] = useState('');
    const [category, setCategory] = useState('');
    const [testimonyDesc, setTestimonyDesc] = useState('');
    const [testimonyTitle, setTestimonyTitle] = useState('');

    const [uploadedFile, setUploadedFile] = useState({});
    const [message, setMessage] = useState('');
    const [bgColor, setBgColor] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showUploadBar, setShowUploadBar] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [success, setSuccess] = useState(false);
    const [successmessage, setSuccessMessage] = useState(false);
    const [error, setError] = useState('');
    const [errormessage, setErrorMessage] = useState('');

     // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

    function saveFile(e) {
      setFile(e.target.files[0]);
      setFilename(e.target.files[0].name);
      console.log("FILES", file, filename);
      
    }

    let storage = ''
    if(sessionStorage.getItem('churchdetails')) {
      storage = sessionStorage.getItem('churchdetails')  
    } else  if(sessionStorage.getItem('memberdetails')) {
      storage = sessionStorage.getItem('memberdetails')
    } else {
      storage = null
    }


    console.log("Church Details: ", storage)

    const initials = {
      testifier:'',
      testifierchurch:'',
      testimonyTitle: '',
      testimonyDesc:'',
      churchunit:'',
      category:''
   }

     
   const formik = useFormik({
     initialValues: {
        file: '',
        filename: '',
        testifier:'',
        testimonyTitle: '',
        testifierchurch:'',
        testimonyDesc:'',
        churchunit:'',
        category:'',
     },
     onSubmit: async (values) => {
        setIsSubmitting(true)
        setShowUploadBar(true)
        //formik.handleSubmit();
        //const myVals = values;
        //(e)=>settestifier(e.target.testifier)
      // alert(JSON.stringify(values, null, 2));
    
       let memdetels = sessionStorage.getItem('memberdetails') !== null ? sessionStorage.getItem('memberdetails') : sessionStorage.getItem('churchdetails')
       console.log("memdetels", memdetels)
       let midArr = JSON.parse(""+memdetels+"");
       //const chid = midArr.churchDetails.churchid;
       //onst chid = 4;
       //const churchname = midArr.churchDetails.churchname;
       //const churchname = "RCCG";
      
       const {testifier, testifierchurch, testimonyTitle, testimonyDesc, churchunit} = values;
       const formData = new FormData();
       formData.append('file', file);
       formData.append('filename', filename);
       formData.append('testifier', testifier);
       formData.append('testifierchurch', testifierchurch);
       formData.append('testimonyTitle', testimonyTitle);
       formData.append('testimonyDesc', testimonyDesc);
       formData.append('churchunit', category);
       
       if(midArr.usertype === "member"){
        formData.append('churchid', midArr.member.churchid);
        formData.append('testifierid', midArr.member.memberid);
       } else {
        formData.append('churchid', midArr.member.churchid);
        formData.append('testifierid', midArr.member.churchid);
       }

       console.log("Values", values)
   
       const host = 'http://localhost:3050'
      
       try {
         await axios.post(`${host}/api/testify/uploadchurch`, formData, {
           /* headers: new Headers({
             Accept: 'application/json',
             'Content-Type': 'multipart/form-data',
             'Access-Control-Allow-Origin':  'http://localhost:3050',
              'Access-Control-Allow-Headers': 'Content-Type, Authorization' 
           }), */
           headers: {
            'Content-Type': 'multipart/form-data'
           }, 
           onUploadProgress: progressEvent => {
             setUploadPercentage(
               parseInt(
                 Math.round((progressEvent.loaded * 100) / progressEvent.total)
               )
             );
           }
          }).then(function(res) {
         
         // Clear percentage
         setTimeout(() => setUploadPercentage(0), 8000);
   
         const { fileName, filePath } = res.data;
   
         setUploadedFile({ fileName, filePath });
         console.log("MY RES DATA IS: ", res.data)
         if(res.data.status === true){
          setSuccess(!success);
          setSuccessMessage(res.data.message);
          setTimeout(function(){
            setSuccessMessage('');
            setSuccess(false);
            },6000);
          }
    
       if(res.data.status === false){
          setError(!error);
          setErrorMessage(res.data.message);
          setTimeout(function(){
          setError(false);
            },6000);
        }
         /* if(res.data.status === 'success'){
          setShowUploadBar(true)
          setMessage(`${res.data.fileName} has been uploaded`);
          setTimeout(function(){
          setMessage('');
          },6000);
          //setIsSubmitting(false)
         }else{
          setShowUploadBar(false)
          setMessage(res.data.msg);
          setTimeout(function(){
            setMessage('');
          },6000);
          //setIsSubmitting(false)
         } */
         setIsSubmitting(false)  
         setShowUploadBar(false)
        })
       } catch (err) {
         if (err) {
          console.log(err)
          setError(!error);
          setErrorMessage("Sorry, this testimony post has already been submitted. Thanks");
          setTimeout(function(){
            setErrorMessage("");
            setError(false);
          
            },6000);
           setIsSubmitting(false)
         } 
         setUploadPercentage(0)
       }
     },
   });
   
 
   return (
    <Fragment>
        <SimpleGrid>
        <Flex mt={{ base: "10%" }} >
        <div className="container continum"  style={{width: '90vw',zIndex:'0'}}>
        <div style={{position: 'relative',top:'2%',right:'2%',zIndex:'9999999999999999'}}>  
        {/*  {message !== ""? <Message msg={message} bgColor={bgColor}/> : null} */}
        {message ? 
        <Message msg={message} bgColor={bgColor}/>
        : null}
        </div>
        <div className="row">
            <div className="col-sm-12 offset-sm-2 mt-5">
            
                <div className="card col-sm-12 my-card">
        <Formik initialValues={{ initials }} > 

        {(formProps) => (  <form   onSubmit={formik.handleSubmit} encType="multipart/form-data">

    <Flex flexDirection="column">
        <FormControl>
        <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Your Name (Testifier) <Text color={brandStars}>*</Text>
        </FormLabel>
        <Input
            isRequired={true}
            variant='auth'
            width='60%'
            background='#fff'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='text'
            placeholder='Enter Your Name'
            mb='24px'
            fontWeight='500'
            size='md'
            id="testifier"
            name="testifier"
            onChange={formik.handleChange}
            value={formik.values.testifier}
            required
        />
        </FormControl>

        <FormControl>
        <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Your Church Name <Text color={brandStars}>*</Text>
        </FormLabel>
        <Input
            isRequired={true}
            variant='auth'
            width='60%'
            background='#fff'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='text'
            placeholder='Enter Your Church Name'
            mb='24px'
            fontWeight='500'
            size='md'
            id="testifierchurch"
            name="testifierchurch"
            onChange={formik.handleChange}
            value={formik.values.testifierchurch}
            required
        />
        </FormControl>

        <FormControl>
        <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Testimony Title <Text color={brandStars}>*</Text>
        </FormLabel>
        <Input
            isRequired={true}
            variant='auth'
            width='60%'
            background='#fff'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='text'
            placeholder='Enter Your Testimony Title'
            mb='24px'
            fontWeight='500'
            size='md'
            id="testimonyTitle"
            name="testimonyTitle"
            onChange={formik.handleChange}
            value={formik.values.testimonyTitle}
            required
        />
        </FormControl>

        
        <FormControl>
        <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Testimony Category <Text color={brandStars}>*</Text>
        </FormLabel>
        <Select 
            icon={<MdArrowDropDown />} 
            placeholder='Select Your Testimony Category' 
            mb='24px' 
            isRequired={true}
            variant='auth'
            width='60%'
            background='#fff'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            fontWeight='500'
            size='md'
            id="churchunit"
            name="churchunit"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
           >
              <option value='Health'>Health</option>
              <option value='Finance'>Finance</option>
              <option value='Career'>Career</option>
              <option value='Relationship'>Relationship</option>
              <option value='Protection'>Protection</option>
              <option value='Fruit of the womb'>Fruit of the womb</option>
        </Select>
       {/*  <Input
            isRequired={true}
            variant='auth'
            width='60%'
            background='#fff'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='text'
            placeholder='Type in e.g Health, Relationship, Career, Finance, Protection or Fruits(of the womb)'
            mb='24px'
            fontWeight='500'
            size='md'
            id="churchunit"
            name="churchunit"
            onChange={formik.handleChange}
            value={formik.values.churchunit}
            required
        /> */}
        </FormControl>

        <FormControl>
        <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Your Testimony Description<Text color={brandStars}>*</Text>
        </FormLabel>
        <Textarea 
            placeholder='Type in Testimony Description' 
            isRequired={true}
            variant='auth'
            border="2px solid #eee"
            width='60%'
            background='#fff'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            mb='24px'
            fontWeight='500'
            size='md'
            id="testimonyDesc"
            name="testimonyDesc"
            onChange={formik.handleChange}
            value={formik.values.testimonyDesc}

        />
        {/* <Input
            isRequired={true}
            variant='auth'
            width='60%'
            background='#fff'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='text'
            placeholder='Describe Your Testimony'
            mb='24px'
            fontWeight='500'
            size='md'
            id="testimonydesc"
            name="testimonydesc"
            onChange={formik.handleChange}
            value={formik.values.testimonydesc}
            required
        /> */}
        </FormControl>
    </Flex>

    


    <div className="form-group">
    <label  className="mg-bottomx">Upload your Photo</label>
    <div className="dragArea">
    <input
         type='file'
         name='file'
         className='custom-file-input whitebg'
         id='customFile'
       /*   className="form-control whitebg" */
        // onChange={(event)=>formProps.setFieldValue("file", event.target)}  
         onChange={(event)=>saveFile(event)}  
        />
            {/*  <input id="file" name="file" type="file" onChange={(event) => {
                    setFile("file", event.currentTarget.files[0]);
                  }} className="form-control" /> */}
    </div>
    </div>

    <div className="form-group">       
    <label className='custom-file-label' htmlFor='customFile'>
   {/*  {filename} */}
    </label>
    {showUploadBar && <Progress percentage={uploadPercentage} />}
    
    </div>

    {/* <span align="center" style={{position:'relative',left:'-7%'}}>-
            <div id='succeed'></div>
            <div className='progress' id="progressDivId">
                <div className='progress-bar' id='progressBar'></div>
                <div className='percent' id='percent'>0%</div>
                
            </div>
    </span> */}
        
            <div className="resultImageWrapper" id="my_canvas">
        <img src="" alt="" id="resultImage" width="200px" />
    </div>
    <div style={{height: '10'}}></div>
            <div id='outputImage'></div>
            

    <div className="form-group"> 
 {/*    <button type="submit" disabled={isSubmitting} className="btn btn-primary"  value='Upload'>
        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
        Submit & FileUpload <i className="fa fa-send fa-2x"></i>
    </button> */}

    <Button
        type="submit"
       /*  disabled={isSubmitting} */ 
        fontSize='sm'
        variant='brand'
        fontWeight='500'
        w='60%'
        h='50'
        mt='20px'
        mb='24px'>
        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
        Submit Testimony <i className="fa fa-send fa-2x"></i>
    </Button>
    
    </div>
    </form>
    )}

    </Formik>

    </div>
    </div>
    </div>
    </div>
    </Flex>
    </SimpleGrid>
    {error && (<Box display="flex" position="fixed" top="20%" left="20%"> 
          <Alert status='error'>
            <AlertIcon />
            {errormessage}
          </Alert>
        </Box>)
        }  
        
       { success && (<Box display="flex" position="fixed" top="20%" left="20%">  
          <Alert status='success' variant='subtle'>
            <AlertIcon />
            {successmessage}
          </Alert>
        </Box>)
        }


 {/*    <SimpleGrid>
      <FileUpload3 />
    </SimpleGrid> */}
</Fragment>
);
 };

 export default  PostATestimony 


 