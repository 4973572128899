import  React from "react";
import {useState, useEffect} from "react";
//import { Table, Thead, Tbody, Tr, Th, Td } from "./testables/table/table";
import { Table, Thead, Tbody, Tr, Th, Td, chakra } from '@chakra-ui/react'
import { TableCaption } from "@chakra-ui/react";
import axios from "axios";

export default function TableExample() {
  const [loaded, setLoaded] = useState(false);
  const [members, setMembers] = useState([]);
  const [counter, setCounter] = useState(0);

  const host = 'http://localhost:3050';
  useEffect(() => {
        async function fetcher() {
          await axios.get(`${host}/api/testify/allmembers`)
          .then(function (result) {
            setLoaded(true);
            setMembers(result.data.data);
            setCounter(result.data.count);
            
          })
        }
        fetcher();
}, []);
console.log("Members DATA", members);
  return (
    <Table className="responsiveTable" variant="striped" colorScheme="blue" size="md" mt='10%'>
      <TableCaption>Goodnews Membership</TableCaption>
      <Thead>
        <Tr>
          <Th>Member ID</Th>
          <Th>Church ID</Th>
          <Th>Full Name</Th>
          <Th>Pastor Name</Th>
          <Th>Gender</Th>
          <Th>City</Th>
          <Th>Country</Th>
          <Th>Mobile No</Th>
          <Th>Register On</Th>
        </Tr>
      </Thead>
      <Tbody>
      {members.map((member, index) => (
        <Tr>
          <Td>{member.memberid}</Td>
          <Td>{member.churchid}</Td>
          <Td>{member.member_name}</Td>
          <Td>{member.pastor_name}</Td>
          <Td>{member.gender}</Td>
          <Td>{member.city}</Td>
          <Td>{member.country}</Td>
          <Td>{member.mobileno}</Td>
          <Td>{member.createdAt.split('T')[0]}</Td>
        </Tr>
        ))
      }
       
      </Tbody>
    </Table>
  );
}
