/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import { NavLink, useLocation, useHistory  } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import axios from 'axios'
import queryString from 'query-string'
import './signin.module.css'
//import loader from '../images/loader3.svg'

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
 
  if(window.innerWidth > 650) {
    setTimeout(() => {
      document.documentElement.style.backgroundColor = "white";
    }, 200);
  }

  const history = useHistory();

  const [show, setShow] = React.useState(false);
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [success, setSuccess] = React.useState(false);
  const [verifySuccess, setVerifySuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [loginText, setLoginText] = React.useState('Sign In');

  const handleClick = () => setShow(!show);

  //const qs = queryString.parse(location.search);
  const search = useLocation().search;

  // This means if ? is found in the url
  //const tokenExists = new URLSearchParams(search).indexOf("?") !== -1;

  const tokenExists = new URLSearchParams(search).get('token');

  React.useEffect(() => {
    let first = false;
    if(!first) {
      if(tokenExists !== '') {
        verifymony();
      }
    }
    return () => {
      first = true
    }
  }, [])

async function verifymony() {
    const host = 'http://localhost:3050';
  
    await axios.post(`${host}/api/testify/email-church-verify/${tokenExists}`)
    .then(function (result) {
      console.log("RESPONSE", result);
      if(result.data.success === true) {
        setVerifySuccess(!verifySuccess)

        setTimeout(() => {
          setVerifySuccess(false)
      }, 5000)
  
      } 
    })
  .catch(function (err) {
    //throw err
      if(err) {
        setError(!error) 
      }
      setTimeout(() => {
        setError(false)
      }, 6000)
      //console.log(err.response.data.status);
    });
}
  

  
  async function loginNow() {
    const myLoginForm = {
      email,
      password
    }

  const host = 'http://localhost:3050';
  
  setLoginText('logging in, please wait...')

  console.log("Form Data", myLoginForm);

  await axios.post(`${host}/api/testify/login`, myLoginForm)
  .then(function (result) {
    console.log("RESPONSE", result);
    if(result.status === 200) {
      setSuccess(!success)
      setLoggedIn(!loggedIn)
      sessionStorage.setItem('churchdetails', JSON.stringify(result.data));
      sessionStorage.setItem('userstatus', 'church');
       history.push("/admin/profile");
  setTimeout(() => {
        setSuccess(false)
      }, 6000)
    } 
  })
  .catch(function (err) {
    if(err) {
      setError(!error);
      setTimeout(() => {
        setLoginText('Sign in')
      }, 6000) 
    }
    setTimeout(() => {
      setError(false);
      setLoginText('Sign in')
    }, 6000)
    console.log(err);
  });
}

loggedIn ? localStorage.setItem('loggedIn', 'loggedIn') :  localStorage.removeItem('loggedIn')

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "-30px", md: "14vh" }}
        flexDirection='column'>

        <Box me='auto'><br/>
          <Heading color={textColor} fontSize='36px' mb='10px' mt='60px'>
             Sign In Church
          </Heading>
        </Box>

        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <Button
            fontSize='sm'
            me='0px'
            mb='26px'
            py='15px'
            h='50px'
            borderRadius='16px'
            bg={googleBg}
            color={googleText}
            fontWeight='500'
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}>
            <Icon as={FcGoogle} w='20px' h='20px' me='10px' />
            Sign in with Google
          </Button>
          <Flex align='center' mb='25px'>
            <HSeparator />
            <Text color='gray.400' mx='14px'>
              or
            </Text>
            <HSeparator />
          </Flex>
          <Flex>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Enter your email and password to sign in!
          </Text>
          </Flex>
          <FormControl>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='mail@simmmple.com'
              mb='24px'
              fontWeight='500'
              size='lg'
              value={email}
              onChange={(e)=>setEmail(e.target.value)}
            />
            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'>
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
              <Input
                isRequired={true}
                fontSize='sm'
                placeholder='Min. 8 characters'
                mb='24px'
                size='lg'
                type={show ? "text" : "password"}
                variant='auth'
                value={password}
                onChange={(e)=>setPassword(e.target.value)}
              />
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Flex justifyContent='space-between' align='center' mb='24px'>
              <FormControl display='flex' alignItems='center' mb='40px'>
              <Checkbox
                  defaultChecked = {localStorage.getItem('loggedIn') ? true : false}
                  id='remember-login'
                  colorScheme='brandScheme'
                  me='10px'
                  onChange={(e)=>setLoggedIn(!loggedIn)}
                />
               {/*   {"loggedIn Status is "+ loggedIn}  */}
                <FormLabel
                  htmlFor='remember-login'
                  mb='0'
                  fontWeight='normal'
                  color={textColor}
                  fontSize='sm'>
                  Keep me logged in
                </FormLabel>
              </FormControl>
              <NavLink to='/auth/forgot-password'>
                <Text
                  color={textColorBrand}
                  fontSize='sm'
                  w='124px'
                  fontWeight='500'>
                  Forgot password?
                </Text>
              </NavLink>
            </Flex>
            <Button
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'
              onClick={(e)=>loginNow(e)}>
              {/* Sign In */}{loginText}
            </Button>
          </FormControl>
          {error && (<Box display="flex" position="fixed" top="20%" left="20%"> 
          <Alert status='error'>
            <AlertIcon />
            Your Login is invalid. Please try again
          </Alert>
        </Box>)
        }  
        
       { success && (<Box display="flex" position="fixed" top="20%" left="20%">  
          <Alert status='success' variant='subtle'>
            <AlertIcon />
               Your Login was successful! Now redirecting....
          </Alert>
        </Box>)
        }

{ verifySuccess && (<Box display="flex" position="fixed" top="20%" left="20%">  
          <Alert status='success' variant='subtle'>
            <AlertIcon />
               Thank you! Your email verification was successful. You can now login
          </Alert>
        </Box>)
        }
    
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'
            mb='12px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              Not registered yet?
              <NavLink to='/auth/sign-up-church'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  Create a Church Account
                </Text>
              </NavLink>
            </Text>

            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              Not a church Admin?
              <NavLink to='/auth/sign-in-member'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  Click Here To Login as a member
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
