import * as React from "react";
import {useState, useEffect} from "react";
//import { Table, Thead, Tbody, Tr, Th, Td } from "./testables/table/table";
import { Table, Thead, Tbody, Tr, Th, Td, chakra } from '@chakra-ui/react'
import { TableCaption } from "@chakra-ui/react";
import axios from "axios";

export default function TableExample() {
  const [loaded, setLoaded] = useState(false);
  const [churches, setChurches] = useState([]);
  const [counter, setCounter] = useState(0);

  const host = 'http://localhost:3050';
  const timer = (myTime,divider) => {
    return myTime.split(divider)[0]
  }
  useEffect(() => {
        async function fetcher() {
          await axios.get(`${host}/api/testify/allchurches`)
          .then(function (result) {
            setLoaded(true);
            setChurches(result.data.data);
            setCounter(result.data.count);
            
          })
        }
        fetcher();
}, []);
console.log("Churches DATA", churches);
  return (
    <Table  className="responsiveTable" variant="striped" colorScheme="blue" size="lg" mt='10%'>
      <TableCaption>Goodnews Church Register</TableCaption>
      <Thead>
        <Tr>
          <Th>Church ID</Th>
          <Th>Church Name</Th>
          <Th>Church Address</Th>
          <Th>Pastor Name</Th>
          <Th>City</Th>
          <Th>Country</Th>
          <Th>Registered On</Th>
        </Tr>
      </Thead>
      <Tbody>
      {churches.map((church, index) => (
        <Tr>
          <Td>{church.churchid}</Td>
          <Td>{church.churchname}</Td>
          <Td>{church.address}</Td>
          <Td>{church.pastor_name}</Td>
          <Td>{church.city}</Td>
          <Td>{church.country}</Td>
          <Td>{church.createdAt.split('T')[0]}</Td>
        </Tr>
           ))
          }
      </Tbody>
    </Table>
  );
}
