/* import React from "https://cdn.skypack.dev/react@17.0.1";
import ReactDOM from "https://cdn.skypack.dev/react-dom@17.0.1"; */
import {
    Avatar,
    Box,
    chakra,
    Container,
    Flex,
    //Icon,
    SimpleGrid,
    useColorModeValue,
    Input,
    Select,
    Text
  } from '@chakra-ui/react';
  import { TriangleDownIcon  } from '@chakra-ui/icons'

import React, { useState, useEffect, useRef }  from 'react';
import {useLocation} from 'react-router-dom';
import axios from 'axios'
import { object } from 'joi';

//import { useState, useEffect } from "https://cdn.skypack.dev/react";

// Note: the empty deps array [] means
// this useEffect will run once
//function App() {
function SearchPaginate2b(props) {
    const [error, setError] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [query, setQuery] = useState("");
    const [filter, setFilter] = useState("");
    const [catter, setCatter] = useState("");
    const [paginate, setpaginate] = useState(6);
    const [render, setRender] = useState(false);
    const searchRef = useRef(null)
    const location = useLocation()
    let checkQSr = new URLSearchParams(location.search).get("ct");
    useEffect(() => {
      let we = false;
      let qset = props.cat;
   
      let checkQS = new URLSearchParams(location.search).get("ct");
      console.log("QS", checkQS) 
     
      if(!we) {
        if(checkQS === null) {

        } else {
          setQuery(qset.charAt(0).toLowerCase() + qset.slice(1));
        }
        //searchRef.current.value = props.cat.charAt(0).toLowerCase() + props.cat.slice(1);
        searchRef.current.focus();  
      }
      (function() {
        we = true;
      })() 
    }, [])

/*     useEffect(() => {
      var event = new Event('change', { bubbles: true });
      searchRef.current.dispatchEvent(event); // ref to the select control
  }, [props.cat]); */

    let fLCapitalize = s => s = s.charAt(0).toUpperCase() + s.slice(1);

    const setNRedirect = ({churchid, churchname, title, id, churchunit, updatedAt, memberid, photo, testifiername, testimonyDesc, index}) =>{
      sessionStorage.setItem('singleTitle', title);
      sessionStorage.setItem('singleDesc', testimonyDesc);
      sessionStorage.setItem('singleId', id);
      sessionStorage.setItem('image', photo);
      sessionStorage.setItem('timer', updatedAt);
      sessionStorage.setItem('testifiername', testifiername);
      let daty = new Date();
      setTimeout(() => {
       // return <Redirect push to={`/#/admin/single`} />
       window.location.href = "http://localhost:3000/#/admin/single?cs="+daty+"#homek"
      }, 20);
    }
  

   /*  interface TestimonialCardProps {
        items: array;
        name: string;
        role: string;
        content: string;
        avatar: string;
        index: number;
      } */
      const backgrounds = [
        `url("data:image/svg+xml, %3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'560\' height=\'185\' viewBox=\'0 0 560 185\' fill=\'none\'%3E%3Cellipse cx=\'102.633\' cy=\'61.0737\' rx=\'102.633\' ry=\'61.0737\' fill=\'%23ED64A6\' /%3E%3Cellipse cx=\'399.573\' cy=\'123.926\' rx=\'102.633\' ry=\'61.0737\' fill=\'%23F56565\' /%3E%3Cellipse cx=\'366.192\' cy=\'73.2292\' rx=\'193.808\' ry=\'73.2292\' fill=\'%2338B2AC\' /%3E%3Cellipse cx=\'222.705\' cy=\'110.585\' rx=\'193.808\' ry=\'73.2292\' fill=\'%23ED8936\' /%3E%3C/svg%3E")`,
        `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='560' height='185' viewBox='0 0 560 185' fill='none'%3E%3Cellipse cx='457.367' cy='123.926' rx='102.633' ry='61.0737' transform='rotate(-180 457.367 123.926)' fill='%23ED8936'/%3E%3Cellipse cx='160.427' cy='61.0737' rx='102.633' ry='61.0737' transform='rotate(-180 160.427 61.0737)' fill='%2348BB78'/%3E%3Cellipse cx='193.808' cy='111.771' rx='193.808' ry='73.2292' transform='rotate(-180 193.808 111.771)' fill='%230BC5EA'/%3E%3Cellipse cx='337.295' cy='74.415' rx='193.808' ry='73.2292' transform='rotate(-180 337.295 74.415)' fill='%23ED64A6'/%3E%3C/svg%3E")`,
        `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='560' height='185' viewBox='0 0 560 185' fill='none'%3E%3Cellipse cx='102.633' cy='61.0737' rx='102.633' ry='61.0737' fill='%23ED8936'/%3E%3Cellipse cx='399.573' cy='123.926' rx='102.633' ry='61.0737' fill='%2348BB78'/%3E%3Cellipse cx='366.192' cy='73.2292' rx='193.808' ry='73.2292' fill='%230BC5EA'/%3E%3Cellipse cx='222.705' cy='110.585' rx='193.808' ry='73.2292' fill='%23ED64A6'/%3E%3C/svg%3E")`,
        `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='560' height='185' viewBox='0 0 560 185' fill='none'%3E%3Cellipse cx='457.367' cy='123.926' rx='102.633' ry='61.0737' transform='rotate(-180 457.367 123.926)' fill='%23ECC94B'/%3E%3Cellipse cx='160.427' cy='61.0737' rx='102.633' ry='61.0737' transform='rotate(-180 160.427 61.0737)' fill='%239F7AEA'/%3E%3Cellipse cx='193.808' cy='111.771' rx='193.808' ry='73.2292' transform='rotate(-180 193.808 111.771)' fill='%234299E1'/%3E%3Cellipse cx='337.295' cy='74.415' rx='193.808' ry='73.2292' transform='rotate(-180 337.295 74.415)' fill='%2348BB78'/%3E%3C/svg%3E")`,
      ];

      function TestimonialCard(props) {
        const { churchid, churchname, id, title, churchunit, updatedAt, memberid, photo, testifiername, testimonyDesc, index } = props;
        return (
          <Flex
            boxShadow={'lg'}
            maxW={'640px'}
            direction={{ base: 'column-reverse', md: 'row' }}
            width={'full'}
            rounded={'xl'}
            p={10}
            justifyContent={'space-between'}
            position={'relative'}
            bg={useColorModeValue('white', 'gray.800')}
            _after={{
              content: '""',
              position: 'absolute',
              height: '21px',
              width: '29px',
              left: '35px',
              top: '-10px',
              backgroundSize: 'cover',
              backgroundImage: `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='21' viewBox='0 0 29 21' fill='none'%3E%3Cpath d='M6.91391 21C4.56659 21 2.81678 20.2152 1.66446 18.6455C0.55482 17.0758 0 15.2515 0 13.1727C0 11.2636 0.405445 9.43939 1.21634 7.7C2.0699 5.91818 3.15821 4.3697 4.48124 3.05454C5.84695 1.69697 7.31935 0.678787 8.89845 0L13.3157 3.24545C11.5659 3.96667 9.98676 4.94242 8.57837 6.17273C7.21266 7.36061 6.25239 8.63333 5.69757 9.99091L6.01766 10.1818C6.27373 10.0121 6.55114 9.88485 6.84989 9.8C7.19132 9.71515 7.63944 9.67273 8.19426 9.67273C9.34658 9.67273 10.4776 10.097 11.5872 10.9455C12.7395 11.7939 13.3157 13.1091 13.3157 14.8909C13.3157 16.8848 12.6542 18.4121 11.3311 19.4727C10.0508 20.4909 8.57837 21 6.91391 21ZM22.5982 21C20.2509 21 18.5011 20.2152 17.3488 18.6455C16.2391 17.0758 15.6843 15.2515 15.6843 13.1727C15.6843 11.2636 16.0898 9.43939 16.9007 7.7C17.7542 5.91818 18.8425 4.3697 20.1656 3.05454C21.5313 1.69697 23.0037 0.678787 24.5828 0L29 3.24545C27.2502 3.96667 25.6711 4.94242 24.2627 6.17273C22.897 7.36061 21.9367 8.63333 21.3819 9.99091L21.702 10.1818C21.9581 10.0121 22.2355 9.88485 22.5342 9.8C22.8756 9.71515 23.3238 9.67273 23.8786 9.67273C25.0309 9.67273 26.1619 10.097 27.2715 10.9455C28.4238 11.7939 29 13.1091 29 14.8909C29 16.8848 28.3385 18.4121 27.0155 19.4727C25.7351 20.4909 24.2627 21 22.5982 21Z' fill='%239F7AEA'/%3E%3C/svg%3E")`,
            }}
            _before={{
              content: '""',
              position: 'absolute',
              zIndex: '-1',
              height: 'full',
              maxW: '640px',
              width: 'full',
              filter: 'blur(40px)',
              transform: 'scale(0.98)',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              top: 0,
              left: 0,
              backgroundImage: backgrounds[index % 4],
            }}>
            <Flex
              direction={'column'}
              textAlign={'left'}
              style={{zIndex: '99999999999999999999999999999999999999999999999', backgroundColor: 'white'}}
              justifyContent={'space-around'}>


              <chakra.p
                flex={1}
                fontFamily={'DM Sans'}
                fontWeight={'medium'}
                fontSize={'15px'}
                pb={4}>
                {<h3 style={{fontSize: '20px'}}><b>{`${title}`}</b></h3>}
              </chakra.p>
              <chakra.p
                 flex={2}
                fontFamily={'DM Sans'}
                fontWeight={'medium'}
                fontSize={'15px'}
                pb={4}>
                {fLCapitalize(testimonyDesc).substring(0, 110)+"..."}
                <a href="#liko"><Text fontWeight='500' color={'#222'} onClick = {() => setNRedirect(props)} fontSize='sm' style={{cursor: 'pointer'}}>
                  <b>Read More...</b>
              </Text>
              </a>
              </chakra.p>

              <chakra.p fontFamily={'DM Sans'} fontWeight={'bold'} fontSize={14}>
                {testifiername}
                <chakra.span
                  fontFamily={'DM Sans'}
                  fontWeight={'medium'}
                  color={'gray.500'}>
                  {' '}
                  - {churchname} - {churchunit}
                </chakra.span>
              </chakra.p>
            </Flex>
            <Avatar
              src={`/church_upload/${photo}`}
              height={'80px'}
              width={'80px'}
              alignSelf={'center'}
              m={{ base: '0 0 35px 0', md: '0 0 0 50px' }}
            />
          </Flex>
        );
      }
     
      
      let detels = sessionStorage.getItem('memberdetails') ? sessionStorage.getItem('memberdetails') :  sessionStorage.getItem('churchdetails')
      console.log("detels", detels)
      let midArr = JSON.parse(""+detels+"");
      const token = midArr.token ? midArr.token : ""
      console.log("TOKEN", token)
      const host = 'http://localhost:3050';
    useEffect(() => {
        //         const request_headers = new Headers();
        //         const api_key = null;
        //         request_headers.append("Authorization", `Bearer ${api_key}`);
        //         request_headers.append("Content-Type", "application/json");

        //         const request_options = {
        //             method: "GET",
        //             headers: request_headers
        //         };

            async function fetcher() {
              await axios.get(`${host}/api/testify/all`)
              .then(function (result) {
                setLoaded(true);
                setItems(result.data.data.reverse());
                  console.log("RESOORT", result.data.data);
              })
            }
            fetcher();
    }, []);
    console.log("API RESULT", items)
    const data = Object.values(items);

    const search_parameters = Object.keys(Object.assign({}, ...data));
    //const filter_items = [...new Set(data.map((item) => item.region))];
    const filter_items = [...new Set(data.map((item) => item.churchunit))]

    function search(items) {
        return items.filter(
            (item) =>
              item.testimonyDesc.includes(filter) &&
              search_parameters.some((parameter) =>
              item[parameter].toString().toLowerCase().includes(query)
            )
        )
    }

    const load_more = (event) => {
        setpaginate((prevValue) => prevValue + 6);
    };

   /*  if (error) {
        return <>{error.message}</>;
    } else if (!loaded) {
        return <>loading...</>;
    } else { */
        return (
            <div className="wrapper">
              <div className="select">
                      {/*   <select
                           // onChange={(e) => setFilter(e.target.value)}
                           className="search-input chakra-input css-5rf251"
                            onChange={(e) => setQuery(e.target.value)}
                           // className="custom-select"
                            aria-label="Filter By Testimony Category"

                            style={{background: '#fff', borderRadius: '20px', width: '20%', float: 'right', postion: 'relative', top: '-20px', left: '-10px'}}
                        >
                            <option value="">Filter By Category</option>
                            {filter_items.map((item) => (
                                <option value={item.charAt(0).toLowerCase() + item.slice(1)}>{item.charAt(0).toLowerCase() + item.slice(1)}</option>
                            ))}
                        </select> */}

                        <Select 
                        className="search-input chakra-input css-5rf251"
                        onChange={(e) => setQuery(e.target.value)}
                        aria-label="Filter By Testimony Category"
                        style={{background: '#fff', borderRadius: '20px', width: '20%', float: 'right', position: 'relative', top: '-20px', left: '-10px'}}
                        placeholder='Search By Category'
                        >
                         { catter && <option value={catter.charAt(0).toLowerCase() + catter.slice(1)} selected>{catter.charAt(0).toLowerCase() + catter.slice(1)}</option>}
                            {filter_items.map((item) => (
                                 (item.churchunit !== catter || item.churchunit !== checkQSr) && <option value={item.charAt(0).toLowerCase() + item.slice(1)}>{item.charAt(0).toLowerCase() + item.slice(1)}</option>
                            ))}
                        </Select>
                        <span className="focus"></span>
                    </div>
                    

                <div className="search-wrapper" style={{marginBottom: '18px', position: 'relative'}}>
                    <label htmlFor="search-form">
            {/*         <button type="button" class="chakra-button css-ku67pu" style={{position: 'absolute'}}><svg viewBox="0 0 24 24" focusable="false" class="chakra-icon css-1wkz2h6" aria-hidden="true"><path fill="currentColor" d="M23.384,21.619,16.855,15.09a9.284,9.284,0,1,0-1.768,1.768l6.529,6.529a1.266,1.266,0,0,0,1.768,0A1.251,1.251,0,0,0,23.384,21.619ZM2.75,9.5a6.75,6.75,0,1,1,6.75,6.75A6.758,6.758,0,0,1,2.75,9.5Z"></path></svg></button> */}
                        <Input
                            ref={searchRef}
                            type="search"
                            name="search-form"
                            id="search-form"
                            className="search-input chakra-input css-5rf251"
                            placeholder="Search for testimony/testifier/status..."
                            pos="relative"
                            top="-20px"
                            value={catter}
                            onChange={(e) => setQuery(e.target.value)}
                            style={{background: '#fff', borderRadius: '20px', width: '30%', float: 'right', left: '-20px', display: 'none'}}
                        />
                       {/*  <span className="sr-only">Search countries here</span> */}
                        
                    </label>

                   
                </div>

              {/*   <ul className="card-grid"> */}
              <SimpleGrid
                columns={{ base: 1, xl: 2 }}
                spacing={'10'}
                mt={2}
                margin={'0 auto'}>
                    {search(data)
                        .slice(0, paginate)
                        .map((item, index) => (
                         // {testimonials.map((cardInfo, index) => (
                            <TestimonialCard key={index} {...item} index={index} />
                        // ))}
                           /*  <li key={item.alpha3Code}>
                                <article className="card">
                                    <div className="card-image">
                                        <img
                                            src={item.avatar}
                                            alt={item.name}
                                        />
                                    </div>
                                    <div className="card-content">
                                        <h2 className="card-name">
                                            {item.name}
                                        </h2>
                                        <div>{item.content}</div>
                                        <ol className="card-list">
                                            <li>
                                                population:{" "}
                                                <span>{item.population}</span>
                                            </li>
                                            <li>
                                                Region:{" "}
                                                <span>{item.region}</span>
                                            </li>
                                            <li>
                                                Capital:{" "}
                                                <span>{item.capital}</span>
                                            </li>
                                        </ol>
                                    </div>
                                </article>
                            </li> */
                        ))}
           {/*      </ul> */}
           <br/>
           </SimpleGrid>
          <Flex justifyContent="center"><button onClick={load_more} style={{marginTop: '18px', cursor: 'pointer'}}>Load More</button></Flex>
            </div>
        );
    }
//}

export default SearchPaginate2b;
//ReactDOM.render(<App />, document.getElementById("root"));