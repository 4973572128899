/* eslint-disable */
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
// chakra imports
import { Box, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";
import SignInMember from "views/auth/signIn/index-m";
import SignUpMember from "views/auth/signup_member";
import SignUp from "views/auth/signup_church";
import SignIn from "views/auth/signIn";
import RTL from "views/admin/rtl";
import TestimonySingle from "views/admin/default/testimonySingle";
import SearchPaginateCategory from "views/admin/default/searchPaginateCategory";
import SearchPaginateByChurch from "views/admin/default/searchPaginateByChurch";
import MainDashboard2 from "views/admin/default/index2.jsx";

export function SidebarLinks(props) {
  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");

  const { routes, isopen } = props;
 console.log("ISOPEN", isopen)
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };



  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes) => {

    return routes.map((route, index) => {
     
      if (route.category) {
       
          return (
            <>
              <Text
                fontSize={"md"}
                color={activeColor}
                fontWeight='bold'
                mx='auto'
                ps={{
                  sm: "10px",
                  xl: "16px",
                }}
                pt='18px'
                pb='12px'
                key={index}>
                {route.name}
              </Text>
              {createLinks(route.items)}
            </>
          );
              
      } else if (
        route.layout === "/admin" ||
        route.layout === "/auth" ||
        route.layout === "/rtl"
      ) {
        /*  if((route.component !== SignInCentered) || (route.component !== SignInMember) || (route.component !== SignUpChurchCentered) || (route.component !== SignUpMemberCentered) || (route.component !== RTL)) {  */
        return (
     
          <NavLink key={index} to={route.layout + route.path}>
            {route.icon ? (
              <Box style={{display: (route.component == SignInMember || route.component == SignUpMember || route.component == SignUp || route.component == SignIn  || route.component == RTL || route.component == TestimonySingle || route.component == SearchPaginateCategory  || route.component == SearchPaginateByChurch)?'none':'block'}}>
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                  py='5px'
                  ps='10px'>
                  <Flex w='100%' alignItems='center' justifyContent='center'>
                    <Box
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeIcon
                          : textColor
                      }
                      me='18px'>
                      {route.icon}
                    </Box>
                    <Text
                      me='auto'
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : textColor
                      }
                      fontWeight={
                        activeRoute(route.path.toLowerCase())
                          ? "bold"
                          : "normal"
                      }
                       
                      >
                      {route.name}
                    </Text>
                  </Flex>
                  <Box
                    h='36px'
                    w='4px'
                    bg={
                      activeRoute(route.path.toLowerCase())
                        ? brandColor
                        : "transparent"
                    }
                    borderRadius='5px'
                  />
                </HStack>
              </Box>
            ) : (
              <Box>
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                  py='5px'
                  ps='10px'>
                  <Text
                    me='auto'
                    color={
                      activeRoute(route.path.toLowerCase())
                        ? activeColor
                        : inactiveColor
                    }
                    fontWeight={
                      activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                    }
                   
                    >
                    {route.name}
                  </Text>
                  <Box h='36px' w='4px' bg='brand.400' borderRadius='5px' />
                </HStack>
              </Box>
            )}
          </NavLink>
  
        );
      }
    });
  };
  //  BRAND
  return createLinks(routes);
}

export default SidebarLinks;
