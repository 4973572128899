/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Text,
  useColorModeValue,
  SimpleGrid,
} from "@chakra-ui/react";

// Custom components
import Banner from "views/admin/marketplace/components/Banner";
//import TableTopCreators from "views/admin/marketplace/components/TableTopCreators";
import HistoryItem from "views/admin/marketplace/components/HistoryItem";
import NFT from "components/card/NFT";
import Card from "components/card/Card.js";

// Assets
import Nft1 from "assets/img/nfts/Nft1.png";
import Nft2 from "assets/img/nfts/Nft2.png";
import Nft3 from "assets/img/nfts/Nft3.png";
import Nft4 from "assets/img/nfts/Nft4.png";
import Nft5 from "assets/img/nfts/Nft5.png";
import Nft6 from "assets/img/nfts/Nft6.png";
import Avatar1 from "assets/img/avatars/avatar1.png";
import Avatar2 from "assets/img/avatars/avatar2.png";
import Avatar3 from "assets/img/avatars/avatar3.png";
import Avatar4 from "assets/img/avatars/avatar4.png";
import tableDataTopCreators from "views/admin/marketplace/variables/tableDataTopCreators.json";
import { tableColumnsTopCreators } from "views/admin/marketplace/variables/tableColumnsTopCreators";

import  { useMemo, useEffect, useState } from "react";
import { Redirect, Link } from 'react-router-dom'
import axios from 'axios'

export default function Marketplace() {
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  
  const [loaded, setLoaded] = useState(false);
  const [churches, setChurches] = useState([]);
  const [members, setMembers] = useState([]);
  const [scale, setScale] = useState(1)

   // Fetch all churches and members
   useEffect(() => {
    let first =false;
    const host = "http://localhost:3050";
          async function fetcher() {
            await axios.get(`${host}/api/testify/allchurches`)
            .then(function (result) {
              setLoaded(true);
              setChurches(result.data.data.reverse());
              setScale(scale * 0.9)
                console.log("CHURCH RESOORT", result.data.data[0].id);
            })
          }
          if(!first) {
            fetcher();
          }
          (() => {
            first = false
          })()
        }, []);
        console.log("CHURCH API RESULT", churches)

        useEffect(() => {
          let first =false;
          const host = "http://localhost:3050";
                async function fetcher() {
                  await axios.get(`${host}/api/testify/allmembers`)
                  .then(function (result) {
                    setLoaded(true);
                    setMembers(result.data.data.reverse());
                      console.log("MEMBER RESOORT", result.data.data[0].id);
                  })
                }
                if(!first) {
                  fetcher();
                }
                (() => {
                  first = false
                })()
              }, []);
              console.log("CHURCHES API RESULT", churches)
              console.log("MEMBERS API RESULT", members)
        let fLCapital = s => s = s.charAt(0).toUpperCase() + s.slice(1);

        function redirectMember(memberHyd) {

        }

        function redirectChurch(churchHyd, churchname) {
          sessionStorage.setItem('churchid', churchHyd);
          sessionStorage.setItem('churchname', churchname);
          let daty = new Date();
          setTimeout(() => {
            window.location.href ="/#/admin/just-a-church?cs="+daty+"#homek" 

          }, 500)
        }


  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        mb='20px'
        gridTemplateColumns={{ xl: "repeat(3, 1fr)", "2xl": "1fr 0.46fr" }}
        gap={{ base: "20px", xl: "20px" }}
        display={{ base: "block", xl: "grid" }}>
        <Flex
          flexDirection='column'
          gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}>
          <Banner />
          <Flex direction='column'>
            <Flex
              mt='45px'
              mb='20px'
              justifyContent='space-between'
              direction={{ base: "column", md: "row" }}
              align={{ base: "start", md: "center" }}>
             {/*  <Text color={textColor} fontSize='2xl' ms='24px' fontWeight='700'>
                Trending NFTs
              </Text> */}
             {/*  <Flex
                align='center'
                me='20px'
                ms={{ base: "24px", md: "0px" }}
                mt={{ base: "20px", md: "0px" }}>
                <Link
                  color={textColorBrand}
                  fontWeight='500'
                  me={{ base: "34px", md: "44px" }}
                  to='#art'>
                  Health
                </Link>
                <Link
                  color={textColorBrand}
                  fontWeight='500'
                  me={{ base: "34px", md: "44px" }}
                  to='#music'>
                  Finance
                </Link>
                <Link
                  color={textColorBrand}
                  fontWeight='500'
                  me={{ base: "34px", md: "44px" }}
                  to='#collectibles'>
                  Career
                </Link>
                <Link color={textColorBrand} fontWeight='500' style={{position: 'relative', left: '-8px'}} to='#sports'>
                  Relationship
                </Link> 
                <Link color={textColorBrand} fontWeight='500'  style={{position: 'relative', left: '8px'}} to='#sports'>
                  Protection
                </Link>
                <Link color={textColorBrand} fontWeight='500' to='#sports'  style={{position: 'relative', left: '24px'}} >
                  Fruits(Wowb)
                </Link>
              </Flex> */}
            </Flex>
            <SimpleGrid columns={{ base: 1, md: 3 }} gap='20px'>
              <NFT
                name='Health'
                author='By Esthera Jackson'
                bidders={[
                  Avatar1,
                  Avatar2,
                  Avatar3,
                  Avatar4,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                ]}
                image={Nft1}
                currentbid='76'
                download='#'
              />
              <NFT
                name='Finance'
                author='By Nick Wilson'
                bidders={[
                  Avatar1,
                  Avatar2,
                  Avatar3,
                  Avatar4,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                ]}
                image={Nft2}
                currentbid='95'
                download='#'
              />
              <NFT
                name='Career '
                author='By Will Smith'
                bidders={[
                  Avatar1,
                  Avatar2,
                  Avatar3,
                  Avatar4,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                ]}
                image={Nft3}
                currentbid='89'
                download='#'
              />
            </SimpleGrid>
            <Text
              mt='15px'
              mb='26px'
              color={textColor}
              fontSize='2xl'
              ms='24px'
              fontWeight='700'>
             {/*  Recent Testimonies... */}
            </Text>
            <SimpleGrid
              columns={{ base: 1, md: 3 }}
              gap='10px'
              mb={{ base: "20px", xl: "0px" }}
              wrap= 'wrap-all'
              >
              <NFT
                fontSize='2xl'
                name='Relationship'
                author='By Peter Will'
                bidders={[
                  Avatar1,
                  Avatar2,
                  Avatar3,
                  Avatar4,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                ]}
                image={Nft4}
                currentbid='90'
                download='#'
              />
              <NFT
                name='Protection'
                author='By Mark Benjamin'
                bidders={[
                  Avatar1,
                  Avatar2,
                  Avatar3,
                  Avatar4,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                ]}
                image={Nft5}
                currentbid='234'
                download='#'
              />
              <NFT
                name='Fruit of the womb'
                author='By Manny Gates'
                bidders={[
                  Avatar1,
                  Avatar2,
                  Avatar3,
                  Avatar4,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                ]}
                image={Nft6}
                currentbid='80'
                download='#'
              />
            </SimpleGrid>
          </Flex>
        </Flex>
        <Flex
          flexDirection='column'
          gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}>
         {/*  <Card px='0px' mb='20px'>
            {/* TOP CREATORS 
            <TableTopCreators
              tableData={tableDataTopCreators}
              columnsData={tableColumnsTopCreators}
            />
          </Card> */}
          <Card p='0px'  className='mycarder'>
            <Flex
              align={{ sm: "flex-start", lg: "center" }}
              justify='space-between'
              w='100%'
              px='22px'
              py='12px'>
              <Text color={textColor} fontSize='130%' fontWeight='600'>
                <h3>Churches Social Media Handles & Ratings</h3>
              </Text>
              <Button variant='action'>See all</Button>
            </Flex>

            <Flex justifyContent='space-between'>
              <Text flex='5'>&nbsp;</Text>
              <Text flex='1' pos='relative' left='-16px'><b>Likes</b></Text>
              <Text flex='1'  pos='relative' left='-8px'><b>Rating</b></Text>
            </Flex>
            { churches.map(church => (
    
                <HistoryItem
                  key={church.churchid}
                  name={church.churchname}
                  author={church.pastor_name}
                  date={'40'}
                  handle={church.fbpage}
                  image={Nft3}
                  likes='69'
                />
           ))}
          </Card><br/>
          <Card p='0px' className='mycarder'>
            <Flex
              align={{ sm: "flex-start", lg: "center" }}
              justify='space-between'
              w='100%'
              px='22px'
              py='12px'>
              <Text color={textColor} fontSize='130%' fontWeight='600'>
                <h3>Members Social Media Handles & Ratings</h3>
              </Text>
              <Button variant='action'>See all</Button>
            </Flex>

            <Flex justifyContent='space-between'>
              <Text flex='5'>&nbsp;</Text>
              <Text flex='1' pos='relative' left='-16px'><b>Likes</b></Text>
              <Text flex='1'  pos='relative' left='-8px'><b>Rating</b></Text>
            </Flex>
            { members.map(member => (
            <Box onClick={()=>redirectMember(member.memberid)}>
            <HistoryItem
              key={member.memberid}
              name={member.membername}
              author={member.pastor_name}
              date={'40'}
              handle={member.fbpage}
              image={Nft4}
              likes='69'
            /></Box>))
            }
          </Card><br/>
        </Flex>
      </Grid>
      {/* Delete Product */}
    </Box>
  );
}
