import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Heading,
  Link,
  Image,
  Text,
  Divider,
  Flex,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  SpaceProps,
  useColorModeValue,
  Container,
  VStack,
} from '@chakra-ui/react';
import SearchPaginate2 from './searchPaginate2';


const BlogTags = (props) => {

  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map((tag) => {
        return (
          <Tag size={'md'} variant="solid" colorScheme="orange" key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};



export const BlogAuthor = (props) => {
  const url = new URL(window.location.href); // for example
  const dia =  url.searchParams.get("client_id");
  const [loaded, setLoaded] = useState(false);
  const [item, setItem] = useState([]);
  const [data, setData] = useState('');

  
  useEffect(() => {
    let first =false;
    const host = "http://localhost:3050";
          async function fetcher() {
            await axios.get(`${host}/api/testify/${dia}`)
            .then(function (result) {
              setLoaded(true);
              setItem(result.data.data.reverse());
              setData(result.data.data.testimonyDesc);
                console.log("RESOORT", result.data.data.testimonyDesc);
            })
          }
          if(!first) {
            fetcher();
          }
          (() => {
            first = false
          })()
        }, []);
        console.log("API SINGLE RESULT", item)
        let fLCapital = s => s = s.charAt(0).toUpperCase() + s.slice(1);
        


  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
     {/*  <Image
        borderRadius="full"
        boxSize="40px"
        src="https://100k-faces.glitch.me/random-image"
        alt={`Avatar of ${props.name}`}
      /> */}
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{/* {props.date.toLocaleDateString()} */}{sessionStorage.getItem('timer')}</Text>
    </HStack>
  ); 
};
  
const TestimonySingle = () => {
  return (
    <Container maxW={'7xl'} p="12" mt="8%" zIndex="0">
      <Heading as="h1"  id={"liko"}>Read Full Testimony Details</Heading>

    {/* Testimony 1 */}
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between">
        <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          alignItems="center">
          <Box
            width={{ base: '100%', sm: '85%' }}
            zIndex="2"
            marginLeft={{ base: '0', sm: '5%' }}
            marginTop="5%">
            <Flex textDecoration="none" _hover={{ textDecoration: 'none' }}>
              <Image
                borderRadius="sm"
                src={
                  "/church_upload/"+sessionStorage.getItem('image')
                }
                alt="some good alt text"
                objectFit="contain"
                marginRight="30px"
                alignSelf="center"
              />
            </Flex>
          </Box>
          <Box zIndex="1" width="100%" position="absolute" height="100%">
            <Box
              bgGradient={useColorModeValue(
                'radial(orange.600 1px, transparent 1px)',
                'radial(orange.300 1px, transparent 1px)'
              )}
              backgroundSize="20px 20px"
              opacity="0.4"
              height="100%"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '0' }}>
          <BlogTags tags={['Like', 'Dislike']} />
          <Heading marginTop="1">
            <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
             {sessionStorage.getItem('singleTitle')}
            </Link>
          </Heading>
          <Text>
          {sessionStorage.getItem('singleDesc')}
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg">
           {/* {item.testimonyDesc} */}
          </Text>
          <BlogAuthor name = {sessionStorage.getItem('testifiername')} date={new Date('2021-04-06T19:01:27Z')} />
        </Box>
      </Box>
      <VStack mt="80px">
        <SearchPaginate2 />
      </VStack>

    </Container>
  );
};

export default TestimonySingle;